body {
  margin: 0;
  font-family: 'Source Code Pro', monospace;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary: #5F9EA0;
  --secondary: #00A6A6;
  --background: #F0F8FF;
  --text-primary: #36454F;
  --text-secondary: #2F4F4F;
  --accent-one: #BF442F;
  --accent-two: #E0624C;
}
[data-theme='dark'] {
  --primary: #5F9EA0;
  --secondary: #00A6A6;
  --background: #21282D;
  --text-primary: #F0F8FF;
  --text-secondary: #bdc3c7;
  --accent-one: #BF442F;
  --accent-two: #E0624C;
}

html, body{
  background-color: var(--background);
  height: 100%;
}

a,
a:link,
a:visited {
  text-decoration: none;
  color: var(--text-primary);
}
