.app {
  color: var(--text-primary);
  background: radial-gradient(var(--background) 60%, black);
  /*background-color: var(--background);*/
  font-size: large;
  font-weight: bold;
  padding: 20px;
  transition: all .5s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden;
  height: 100vh;
}

button {
  color: var(--text-primary);
  background-color: var(--background);
  border: 2px var(--text-primary) solid;
  float: right;
  transition: all .5s;
}

@media(max-width: 1024px){
  .app{
    overflow: auto;
    flex-direction: column;
    height: auto;
    justify-content: start;
    align-items: start;
  }
}
