.chip {
    background-color: var(--primary);
    border-radius: 15px;
    font-size: 0.8rem;
    padding: 5px 12px 7px 12px;
    margin: 4px 4px;
    flex-wrap: wrap;
    text-wrap: nowrap;
}

.chip:hover {
    color: var(--text-primary);
}